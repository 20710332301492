<template>
  <div>
    <h3 ref="intro">
      Expression
    </h3>
    <p class="mt-3 font-weight-light">
      In mathematics, an expression is made of numbers and variables combined by mathematical operations such as addition, subtraction, multiplication or division.
      <br>
      For example &mdash; \(2x+3\), \(3x + 5\) and \(x/5 +2\) are expressions in a single  variable \(x\). Similarly, \(4 x + 6y\), \(3 x + 4 y\) and \( x + 2 y\) are expressions in two variables \(x\) and \(y\).
    </p>
    <h3 ref="parts">
      Parts of an Expression
    </h3>
    <p class="mt-2">
      There are three parts of an expression &mdash; constant, coefficient and variable.
    </p>
    <h5>Constant</h5>
    <p> Constant refers to fixed numerical value in an expression.</p>
    <h5>Variable</h5>
    <p>Variable is often denoted by a symbol (such as \(x\), \(y\) etc.) and doesn't have a fixed numerical value.</p>
    <h5>Coefficient</h5>
    <p> Coefficient is the mumber multiplied to the variable in the expression.</p>
    <p>
      Let's take an example. Consider the mathematical expression: \(3 x +5\). In this expression, \(3\) is the coefficient, \(x\) is the variable and  \(5\) is the constant.
    </p>
    <h3 ref="playgraph" class="mt-3">
      MagicGraph | Parts of an Expression
    </h3>
    <p class="mt-3">
      In this MagicGraph, students will learn about the various parts of an expression. Simply tap on each part of the expression to see its name. Tap on the shuffle button to generate a new expression.
    </p>

    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Expression',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Parts of an Expression';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Terms of a Polynomial', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Coefficients of a Polynomial', img:'/assets/number-2.svg', action: () => this.goto('formal')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Parts of an Expression',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about parts of an expression.'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
